@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.rightSection {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp(m) {
    padding: 0 24px;
  }

  &.noScroll {
    overflow: hidden;
  }

  .content {
    padding: 0 24px 40px 24px;
    margin-top: 40px;

    @include bp(m) {
      margin-top: 80px;
      padding: 40px 0 40px 0;
    }
  }
}

.mobileDrawer {
  top: 80px;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 8;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px,
    rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;

  .navigationGroup {
    width: 100%;
    margin-top: 20px;

    .groupName {
      box-sizing: border-box;
      list-style: none;
      font-family: $jost;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
      color: $black-color;
      line-height: 1.4;
      padding: 0 22.5px 8px;
    }

    .navigationItem {
      display: flex;
      color: $color-grey;
      width: 100%;
      justify-content: flex-start;
      padding: 14px 24px;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: $jost;
      font-size: 0.875rem;
      line-height: 1.75;
      min-width: 64px;
      border-radius: 0;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 500;
      text-transform: none;
      margin: 0 0 4px;

      &:hover {
        background-color: rgba(25, 118, 210, 0.06);
      }

      &.activeSubItem {
        background-color: rgba(25, 118, 210, 0.12);
        color: $primary-main;
        font-weight: 600;
      }

      .navigationIcon {
        margin-right: 10px;
      }
    }
  }
}

.locationSelectModal {
  :global(.MuiDialog-paperScrollPaper) {
    max-width: 600px !important;
  }
}

.publicContainer {
  min-height: 100vh;
}
